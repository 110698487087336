import Swiper, { Autoplay, Navigation, Pagination } from "swiper";

new Swiper(".swiper-testimonial", {
    loop: true,
    speed: 3000,
    preloadImages: false,
    modules: [Autoplay, Navigation, Pagination],
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        type: "bullets",
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        640: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        1024: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
    },
});
